import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='62px'
      height='54px'
      viewBox='0 0 62 54'
      {...props}
    >
      <path
        fillRule='evenodd'
        fill='rgb(0, 255, 171)'
        d='M60.574,20.884 C56.764,36.679 38.149,43.245 38.149,43.245 L39.90,49.991 C36.267,52.298 31.876,53.364 31.876,53.364 L31.832,0.980 L32.347,1.3 L37.992,39.696 C37.992,39.696 57.655,27.916 53.988,15.735 C50.320,3.554 34.385,6.152 34.385,6.152 C42.853,3.310 64.384,5.85 60.574,20.884 ZM21.930,53.462 L29.54,1.804 L29.985,1.804 L29.818,53.462 C29.818,53.462 25.119,54.222 21.930,53.462 ZM22.744,30.609 L18.662,29.895 L15.612,33.436 L15.760,28.600 L12.594,27.663 L16.713,25.863 L17.827,21.458 L19.725,24.835 L23.895,23.690 L21.184,27.67 L22.744,30.609 ZM20.57,15.235 L18.866,14.899 L17.753,15.466 L18.64,14.502 L17.162,14.164 L18.503,13.998 L19.82,13.167 L19.466,13.933 L20.785,13.901 L19.782,14.449 L20.57,15.235 ZM11.672,23.404 L9.953,23.39 L8.619,24.489 L8.741,22.444 L7.413,22.0 L9.177,21.301 L9.702,19.455 L10.464,20.914 L12.242,20.496 L11.54,21.881 L11.672,23.404 ZM6.265,35.449 L6.389,38.116 L4.31,36.431 L1.302,37.695 L2.543,34.746 L0.806,33.203 L3.783,33.343 L5.520,30.957 L5.892,33.623 L8.746,34.186 L6.265,35.449 ZM14.626,42.3 L16.821,38.987 L17.292,42.358 L20.899,43.69 L17.762,44.665 L17.919,48.38 L14.939,45.908 L11.489,47.507 L13.58,43.779 L10.863,41.827 L14.626,42.3 Z'
      />
    </svg>
  )
}

export default SvgComponent
