import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='37px'
      height='54px'
      viewBox='0 0 37 54'
      {...props}
    >
      <path
        fillRule='evenodd'
        fill='rgb(0, 255, 171)'
        d='M35.851,29.406 C33.579,36.989 27.392,42.671 27.392,42.671 C27.392,42.671 22.891,45.625 22.796,49.250 C22.699,52.874 24.28,53.759 24.28,53.759 L16.538,53.759 L20.995,27.683 C20.995,27.683 23.984,26.570 25.985,24.561 C25.985,24.561 32.880,17.983 35.502,13.946 C35.502,13.946 38.123,21.832 35.851,29.406 ZM19.439,26.679 L15.62,53.700 L7.790,53.700 L15.35,29.711 C15.62,29.545 15.8,29.269 14.939,28.963 C12.570,30.17 10.936,30.549 10.936,30.549 L12.614,21.774 C30.241,15.147 31.578,0.907 31.578,0.907 C37.267,13.641 27.400,22.168 19.439,26.679 ZM5.570,34.842 C4.583,37.68 5.570,40.554 10.19,40.554 L8.560,46.876 C8.560,46.876 0.991,45.812 0.668,36.842 C0.370,27.880 10.910,22.474 10.910,22.474 L9.529,30.943 C9.529,30.943 6.637,32.478 5.570,34.842 Z'
      />
    </svg>
  )
}

export default SvgComponent
