import { Media } from '@cgtalks/common/theme'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

export const Billboard = styled(GatsbyImage)`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
export const BannerContainer = styled.div`
  width: 1920px;
  height: 620px;
  position: relative;
  box-sizing: border-box;
  z-index: 0;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    z-index: -1;
  }
`
export const Title = styled.h1`
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;

  ${Media.lessThan(Media.small)} {
    // padding-top: 105px;
    font-size: 50px;
    white-space: nowrap;
    top: 20%;
  }
`
export const AboutContainer = styled.div`
  width: 1395px;
  background: #1d1d25;
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    width: 80%;
    padding: 30px 20px 45px;
    background: #2b2b37;
    border-radius: 10px;
    margin-top: -353px;
    margin-bottom: 65px;
  }
`
export const SubTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin: 0;
  margin-bottom: 42px;
  ${Media.lessThan(Media.small)} {
    font-size: 18px;
    margin-bottom: 20px;
  }
`
export const AboutContent = styled.div`
  margin-bottom: 96px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 42px;
  }
`
export const AboutParagraph = styled.p`
  color: #c6c6d2;
  font-size: 18px;
  line-height: 32px;
  ${Media.lessThan(Media.small)} {
    font-size: 14px;
    line-height: 28px;
  }
`
export const QualificationCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
  }
`
export const QualificationCard = styled.div`
  width: 450px;
  height: auto;
  background: #383844;
  border-radius: 10px;
  margin-bottom: 29px;
  padding: 23px 0 24px 60px;
  padding-left: 60px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: 10px 0 10px 20px;
    margin-bottom: 15px;
  }
`
export const IconWrapper = styled.div`
  display: inline-block;
  width: 60px;
  margin-right: 24px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 30px;
    margin-right: 12px;
  }
`
export const Qualification = styled.div`
  font-size: 20px;
  color: #c6c6d2;
  display: inline-block;
  ${Media.lessThan(Media.small)} {
    font-size: 15px;
  }
`
export const ContactUsWrapper = styled.div`
  position: relative;
  height: 400px;
  ${Media.lessThan(Media.small)} {
    .contact-us-img {
      display: none;
    }
    height: 135px;
  }
`
export const WechatQRCodeWrapper = styled.div`
  position: absolute;
  top: 94px;
  right: 176px;
  box-sizing: border-box;
  ${Media.lessThan(Media.small)} {
    top: 8px;
    right: unset;
    left: 50%;
    margin-left: -56px;
  }
`
export const TipWrapper = styled.div`
  color: #c6c6d2;
  font-size: 14px;
  margin-top: 25px;
  line-height: 24px;
  p {
    text-align: center;
    margin: 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 18px;
  }
`
