import { Seo } from '@cgtalks/common/components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Billboard from '../../../common/components/billboard'
import { useMedia } from '../../../common/hooks'
import { default as Certified } from '../images/about/icons/Certified'
import { default as Farm } from '../images/about/icons/Farm'
import { default as Iso } from '../images/about/icons/ISO'
import { default as Knowledge } from '../images/about/icons/Knowledge'
import { default as Prize } from '../images/about/icons/Prize'
import { default as Tpn } from '../images/about/icons/TPN'
import Layout from '../molecules/layout'
import {
  AboutContainer,
  AboutContent,
  AboutParagraph,
  BannerContainer,
  ContactUsWrapper,
  IconWrapper,
  Qualification,
  QualificationCard,
  QualificationCardWrapper,
  SubTitle,
  TipWrapper,
  Title,
  WechatQRCodeWrapper,
} from './about.atom'

const About = ({ location, data }) => {
  const isMobile = useMedia('(max-width: 812px)').matches
  const qualificationList = [
    {
      key: 'knowledge',
      title: '知识产权管理体系认证',
      icon: <Knowledge width={isMobile ? '23px' : '60px'} height={isMobile ? '20px' : '53px'} />,
    },
    {
      key: 'certified',
      title: '国家高新企业认证',
      icon: <Certified width={isMobile ? '13px' : '36px'} height={isMobile ? '20px' : '53px'} />,
    },
    {
      key: 'tpn',
      title: 'TPN安全认证服务商',
      icon: <Tpn width={isMobile ? '22px' : '60px'} height={isMobile ? '20px' : '53px'} />,
    },
    {
      key: 'prize',
      title: '中国云渲染平台领军企业奖',
      icon: <Prize width={isMobile ? '19px' : '52px'} height={isMobile ? '20px' : '53px'} />,
    },
    {
      key: 'iso',
      title: 'ISO27001安全认证',
      icon: <Iso width={isMobile ? '19px' : '51px'} height={isMobile ? '20px' : '53px'} />,
    },
    {
      key: 'farm',
      title: '奥斯卡获奖作品渲染农场',
      icon: <Farm width={isMobile ? '9px' : '23px'} height={isMobile ? '20px' : '53px'} />,
    },
  ]
  return (
    <Layout location={location}>
      <Seo
        title='关于泛CG-泛CG聚未来-Renderbus瑞云渲染'
        keywords='泛CG, CG技术线上分享会,cg分享网,CG分享平台'
        description='Renderbus瑞云渲染主办的“泛CG聚未来”，分线上直播、线下交流会，是一个属于CG人的自由分享、交流平台，这里有硬核技术、前沿工具、行业大牛经验等的免费分享。'
        sharePostSlug='about.html'
      />
      <BannerContainer>
        <Title>泛CG 聚未来</Title>
        <Billboard
          fluid={[
            data.Banner.childImageSharp.fluid,
            { ...data.MbBanner.childImageSharp.fluid, media: `(max-width: 780px)` },
          ]}
          alt='Banner'
        />
      </BannerContainer>
      <AboutContainer>
        <AboutContent>
          <SubTitle>关于泛CG</SubTitle>
          <AboutParagraph>
            近年来随着影视市场需求的不断扩大,
            CG影视制作行业生态圈在不断地发展和完善，我们看到越来越多的CG人，从技术变革到艺术创新，为CG影视制作行业的发展贡献着自己的力量。
          </AboutParagraph>
          <AboutParagraph>
            泛CG分享会由瑞云渲染主办，包括线上直播以及线下交流会，旨在打造一个属于CG人的自由分享、交流的平台。在这里，有硬核的技术分享，有前沿的解决工具，有优秀的CG艺术作品鉴赏，有公开自由的交流平台。属于CG人的学习交流机会，就等你来！
          </AboutParagraph>
          <AboutParagraph>
            泛CG活动赞助及合作伙伴包括：英伟达、Autodesk、虚幻引擎、Renderbus瑞云渲染、青椒云、镭速、3DCAT等。
          </AboutParagraph>
          <AboutParagraph>
            主办方深圳市瑞云科技有限公司是一家专注为视觉行业提供垂直云计算服务的公司，用户超20万，遍及50多个国家和地区,包括两位奥斯卡金像奖得主、知名的影视动画、视效、建筑可视化、游戏工作室，瑞云科技旗下云渲染品牌，Renderbus瑞云渲染被誉为中国云渲染先行者，代表案例包括电影《长津湖》、《战狼2》、《哪吒之魔童降世》、《流浪地球》
            等。我们作为亚洲前沿的云渲染平台，除了致力于提供专业可靠、安全稳定、可持续创新的云渲染解决方案，助力推动行业快速发展外，我们也希望通过我们的行业影响力，汇聚更多的CG大咖们，分享他们的技术，讲出他们的故事。
          </AboutParagraph>
        </AboutContent>
        <AboutContent>
          <SubTitle>瑞云资质</SubTitle>
          <QualificationCardWrapper>
            {qualificationList.map((item) => (
              <QualificationCard key={item.key}>
                <IconWrapper>{item.icon}</IconWrapper>
                <Qualification>{item.title}</Qualification>
              </QualificationCard>
            ))}
          </QualificationCardWrapper>
        </AboutContent>
        <AboutContent>
          <SubTitle>联系我们</SubTitle>
          <ContactUsWrapper>
            <GatsbyImage
              image={data.ContactUs.childImageSharp.gatsbyImageData}
              className='contact-us-img'
              alt='contact us'
            />
            <WechatQRCodeWrapper>
              <GatsbyImage
                image={data.WechatQRCode.childImageSharp.gatsbyImageData}
                alt='wechat qr code'
                width={isMobile ? 90 : 180}
              />
              <TipWrapper>
                {isMobile ? (
                  <p>联合直播推广合作</p>
                ) : (
                  <>
                    <p>扫码添加微信小助手沟通细节</p>
                    <p>（非诚勿扰，谢谢！）</p>
                  </>
                )}
              </TipWrapper>
            </WechatQRCodeWrapper>
          </ContactUsWrapper>
        </AboutContent>
      </AboutContainer>
    </Layout>
  )
}
export default About

export const query = graphql`
  query {
    Banner: file(relativePath: { regex: "/about/about_banner.png/" }) {
      ...fluidImage
    }
    MbBanner: file(relativePath: { regex: "/about/about_banner_mobile.png/" }) {
      ...fluidImage
    }
    ContactUs: file(relativePath: { eq: "about/contact_us.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 75, breakpoints: [1920], layout: FULL_WIDTH)
      }
    }
    WechatQRCode: file(relativePath: { eq: "about/wechat_qr_code.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 75, breakpoints: [1920], layout: FULL_WIDTH)
      }
    }
    CgTalkTitle: file(relativePath: { eq: "banner/main-banner/cgtalk.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 75, breakpoints: [1920], layout: FULL_WIDTH)
      }
    }
  }
`
