import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24px'
      height='54px'
      viewBox='0 0 24 54'
      {...props}
    >
      <path
        fillRule='evenodd'
        fill='rgb(0, 255, 171)'
        d='M22.864,47.614 L21.168,47.614 L21.168,38.6 C21.168,37.524 20.789,37.133 20.321,37.133 L15.249,37.133 C14.991,36.524 14.615,35.436 14.615,34.859 C14.615,34.375 14.938,33.207 15.175,32.355 C15.378,31.624 15.552,30.992 15.616,30.555 C15.713,29.875 15.545,28.693 15.316,27.218 C15.243,26.753 15.154,26.173 15.152,26.32 C15.159,25.913 15.265,25.529 15.351,25.218 C15.580,24.391 15.892,23.256 15.892,22.84 C15.892,20.829 15.782,18.848 15.724,17.873 C15.918,17.731 16.138,17.535 16.362,17.261 C17.53,16.408 18.254,11.361 17.676,9.590 C17.288,8.406 15.861,7.633 14.713,7.12 C14.501,6.896 14.235,6.753 14.51,6.640 C14.102,6.424 14.187,6.125 14.256,5.884 C14.508,5.11 14.791,4.22 14.666,3.267 C14.416,1.792 13.195,0.880 12.129,0.880 C11.127,0.880 9.893,1.792 9.647,3.265 C9.519,4.18 9.799,5.3 10.45,5.872 C10.112,6.113 10.197,6.414 10.247,6.630 C10.62,6.741 9.796,6.886 9.582,7.1 C8.433,7.622 7.4,8.396 6.615,9.584 C6.35,11.354 7.233,16.402 7.925,17.251 C8.149,17.526 8.366,17.722 8.563,17.862 C8.502,18.835 8.393,20.806 8.393,22.61 C8.393,23.233 8.706,24.359 8.934,25.180 C9.20,25.486 9.125,25.868 9.131,25.979 C9.130,26.126 9.40,26.700 8.967,27.161 C8.739,28.615 8.572,29.781 8.668,30.461 C8.730,30.890 8.902,31.529 9.101,32.268 C9.342,33.153 9.669,34.368 9.669,34.859 C9.669,35.436 9.294,36.524 9.35,37.133 L4.211,37.133 C3.744,37.133 3.363,37.524 3.363,38.6 L3.363,47.614 L1.667,47.614 C1.200,47.614 0.820,48.5 0.820,48.487 L0.820,52.854 C0.820,53.338 1.200,53.728 1.667,53.728 L22.864,53.728 C23.333,53.728 23.712,53.338 23.712,52.854 L23.712,48.487 C23.712,48.5 23.333,47.614 22.864,47.614 ZM16.81,47.614 L8.450,47.614 L8.450,42.374 L16.81,42.374 L16.81,47.614 Z'
      />
    </svg>
  )
}

export default SvgComponent
